//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ReportPage from "@/components/ReportPage";
import MainTitle from "@/components/MainTitle.vue";
import DataLoading from "@/components/Loading/DataLoading";
// import CalendarMixin from "@/mixins/Calendar";
import RepricerMixin from "@/mixins/Repricer";
import HasTokens from "@/components/HasTokensRepricer.vue";
import WarningRepricer from "@/components/Alert/WarningRepricer.vue";
import ScriptsTable from "@/components/Repricer/ScriptsTable.vue";
import AppSelectMulti from "@/components/AppSelectMulti.vue";
import AppSelect from "@/components/AppSelect.vue";
import DataTableActions from "@/components/DataTable/DataTableActions.vue";
export default {
  mixins: [RepricerMixin],
  meta: {
    title: "Анализ и работа с отзывами"
  },
  data() {
    // const localStorageCalendar = JSON.parse(
    //     localStorage.getItem("repricerCalendar")
    // // );

    // if (localStorageCalendar) {
    //     this.$store.dispatch(
    //         "repricer/updateCalendar",
    //         localStorageCalendar
    //     );
    // }

    return {
      // calendarInternal: null,
      // calendar: this.$store.state.repricer.calendar,
      totalScripts: 0
    };
  },
  computed: {
    tableKey() {
      return `${this.tabs_tables_model.id}`;
    },
    reportSettings() {
      const tokens = this.selectedTokens.map(item => item.id);
      // if (!this.calendar) {
      //     return null;
      // }
      // if (!tokens.length) {
      //     return null;
      // }
      // const dates = [this.calendar[0], this.calendar[1]];
      return {
        // date: dates[0],
        // date2: dates[1],
        tokens
      };
    }
  },
  // watch: {
  //     calendar() {
  //         this.$store.dispatch("repricer/updateCalendar", this.calendar);
  //         localStorage.setItem(
  //             "repricerCalendar",
  //             JSON.stringify(this.calendar)
  //         );
  //     },
  // },
  methods: {
    addScript() {
      // const limit = this.$access.getLimit("repricer");
      // if (this.totalScripts >= limit) {
      //     this.$modal.open(
      //         () =>
      //             import(
      //                 "@/components/modals/access/ModalLimitReachedTemplate"
      //             ),
      //         {
      //             alert: "Превышен лимит на вашем тарифном плане",
      //             title: "Исчерпан лимит сценариев. Для увеличения лимита необходимо перейти на старший тариф",
      //         }
      //     );
      // } else {
      this.$router.push({
        name: "RepricerScript"
      });
      // }
    },

    updateTotal(val) {
      this.totalScripts = val;
    }
  },
  components: {
    MainTitle,
    ReportPage,
    HasTokens,
    WarningRepricer,
    DataLoading,
    ScriptsTable,
    AppSelectMulti,
    AppSelect,
    DataTableActions
  }
};